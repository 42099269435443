import React, { useState } from "react"
import GatsbyImage from "gatsby-image"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { graphql } from "gatsby"
import { BlogPostContent } from "./blog-post-content"
import { FeaturedImage } from "./featured-image"
import "./blog-post.less"

const BlogPostTemplate = ({
  pageContext: { previous, next },
  data: {
    contentfulBlogPost: {
      slug,
      title,
      childContentfulBlogPostPostDescriptionTextNode,
      tagsList,
      subtitle,
      publishDate,
      featuredImage,
      content,
    } = {},
  } = {},
}) => {
  const [headings, setHeadings] = useState([])
  const { postDescription } =
    childContentfulBlogPostPostDescriptionTextNode || {}
  const { fluid, fixed } = featuredImage || {}
  const featuredImagePath = fixed?.src

  const parseHeadingValue = node => node?.content?.[0]?.value?.trim() || null

  const parseHeader = ({ as: Element }) => (node, children) => {
    const id = parseHeadingValue(node)

    if (id && !headings.includes(id)) {
      setHeadings(currentHeadings => [...currentHeadings, id])
    }

    return <Element id={id?.toLowerCase()}>{children}</Element>
  }

  const nodeRenderer = {
    renderNode: {
      [BLOCKS.HEADING_1]: parseHeader({ as: "h1" }),
      [BLOCKS.HEADING_2]: parseHeader({ as: "h2" }),
      [BLOCKS.HEADING_3]: parseHeader({ as: "h3" }),
      [BLOCKS.HEADING_4]: parseHeader({ as: "h4" }),
      [BLOCKS.HEADING_5]: parseHeader({ as: "h5" }),
      [BLOCKS.HEADING_6]: parseHeader({ as: "h6" }),
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const { fluid } = node.data.target
        if (!fluid) {
          return null
        }
        return (
          <div>
            <GatsbyImage fluid={fluid} />
          </div>
        )
      },
    },
  }

  const Content = ({ raw }) => renderRichText(raw, nodeRenderer)

  return (
    <BlogPostContent
      title={title}
      subtitle={subtitle}
      date={publishDate}
      tags={tagsList}
      description={postDescription}
      headings={headings?.map(heading => ({ value: heading }))}
      featuredImage={<FeaturedImage fluid={fluid} />}
      featuredImagePath={featuredImagePath}
      previous={previous}
      next={next}
    >
      <Content raw={content} />
    </BlogPostContent>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      slug
      title
      tagsList
      subtitle
      childContentfulBlogPostPostDescriptionTextNode {
        postDescription
      }
      publishDate(formatString: "MMMM DD, YYYY")
      rawDate: publishDate
      featuredImage {
        ... on ContentfulAsset {
          contentful_id
          __typename
          fluid(maxWidth: 520) {
            ...GatsbyContentfulFluid
          }
          fixed {
            src
          }
        }
      }
      content {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            fluid(maxWidth: 520) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`

export default BlogPostTemplate
