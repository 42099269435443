import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"

export const FeaturedImage = ({ image, children, fluid }) => {
  const hasFeaturedImage = Boolean(image) || Boolean(children) || Boolean(fluid)

  if (!hasFeaturedImage) {
    return null
  }

  if (fluid) {
    return (
      <div className="post__featured-image">
        <Img fluid={fluid} />
      </div>
    )
  }

  if (image && image.childImageSharp) {
    return (
      <div className="post__featured-image">
        <Img fluid={image.childImageSharp.fluid} />
      </div>
    )
  }

  return <div className="post__featured-image">{children}</div>
}

FeaturedImage.defaultProps = {
  image: null,
}

FeaturedImage.propTypes = {
  image: PropTypes.shape({ childImageSharp: PropTypes.shape({}) }),
}
