import React from "react"
import PropTypes from "prop-types"
import Layout from "../../../components/layout/layout"
import TableOfContents from "../table-of-contents/table-of-contents"
import Seal from "../../../components/icons/seal"

export const BlogPostContent = ({
  title,
  subtitle,
  date,
  description,
  featuredImage,
  featuredImagePath,
  headings,
  tags,
  html,
  skeleton,
  previous: previousPage,
  next: nextPage,
  children,
}) => {
  const isChinesePost = (tags || []).indexOf("chinese") > -1
  const parsedDescription = description && description.replace(/\n/g, "<br>")

  return (
    <Layout
      skeleton={skeleton}
      title={title}
      description={description}
      featuredImagePath={featuredImagePath}
      nextPage={nextPage}
      previousPage={previousPage}
      className="post"
    >
      <section>
        <div className="post__title">
          <h1>{title}</h1>
          {subtitle && <h2>{subtitle}</h2>}
          <p className="post__date">
            <small className="light-grey">{date}</small>
          </p>
          {isChinesePost && !skeleton && <Seal />}
          {parsedDescription && (
            <p dangerouslySetInnerHTML={{ __html: parsedDescription }}></p>
          )}
        </div>

        {featuredImage}

        <TableOfContents headings={headings} />

        <div className="post__content">
          {html && <div dangerouslySetInnerHTML={{ __html: html }}></div>}
          {children && <div>{children}</div>}
        </div>
      </section>
    </Layout>
  )
}

BlogPostContent.defaultProps = {
  featuredImage: null,
  skeleton: false,
  tags: [],
  subtitle: "",
  previous: null,
  next: null,
  html: null,
  description: null,
}

BlogPostContent.propTypes = {
  skeleton: PropTypes.bool,
  html: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  date: PropTypes.string.isRequired,
  description: PropTypes.string,
  featuredImage: PropTypes.node,
  headings: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]),
      slug: PropTypes.string,
    })
  ),
  tags: PropTypes.array,
  previous: PropTypes.shape({}), // TODO
  next: PropTypes.shape({}), // TODO
}

export default BlogPostContent
