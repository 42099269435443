import React from "react"
import "./table-of-contents.less"

const whitelist = ["anecdote", "ingredients", "recipe", "method"]

const TableOfContents = ({ headings }) => {
  const links =
    headings &&
    headings
      .map(heading => {
        if (!heading.slug) {
          return {
            ...heading,
            slug: heading.value.toLowerCase(),
          }
        }
        return heading
      })
      .filter(({ slug }) => whitelist.includes(slug.toLowerCase()))

  if (!links || !links.length) {
    return null
  }

  return (
    <div className="post__table-of-contents">
      <ul>
        {links.map(({ value, slug }) => {
          return (
            <li key={`${slug}-link`}>
              <a className="link" href={`#${slug.toLowerCase()}`}>
                {value}
              </a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default TableOfContents
